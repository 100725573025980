import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { HashLink as Lnk } from 'react-router-hash-link';
import {FormattedMessage,useIntl} from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { Link } from 'react-router-dom';
import en from '../../images/lang-en.png'
import rus from '../../images/lang-ru.png'
import ftz from '../../images/ftz.svg'
import ftz_en from '../../images/ftz_en.svg'
import { useState,useEffect} from 'react';
import '../../styles/Main.css'


const Testpage = () => {
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);
    const intl = useIntl()
    var invert = false;
    var prevnext = true;
    const ur = window.location.pathname;
    var lur;
    ur === '/' ? lur = ['4'] : lur = ur.split('/');
    var res = lur[lur.length-1];
    const openImgEditor = () => {setIsImgEditorShown(true);};
    const closeImgEditor  = () => {setIsImgEditorShown(false);};
    useEffect(()=>window.scrollTo({top: 0}),[])
    const changeimg = (val) =>{
        prevnext = val;
        prevnext ? document.getElementById('image').src=require(`./img/test-img-${res}-out.png`):document.getElementById('image').src=require(`./img/test-img-${res}.png`)
        console.log(document.getElementById('image').src)
    }
    const resetparam = () => {
        document.getElementById('con').value=100;
        document.getElementById('br').value=100;
        invert=false
        document.getElementById('image').style.filter = "brightness(100%) contrast(100%)";
    }
    const transreload = (locale) => {
        localStorage.setItem('locale',locale)
        window.location.reload()
    }
    const change = () => {
        var b = document.getElementById('br').value;
        var c = document.getElementById('con').value;
        document.getElementById('image').style.filter="brightness("+b+"%) contrast("+c+"%) invert(0%)"
        if(invert)document.getElementById('image').style.filter+=" invert(100%)"
    }
    return (
        <div className='main' id='testmain'>
            {isImgEditorShown && (
                <div className='edit-div'>
                    <div className='edit-header'>
                        <div className='edit-header-btn' onClick={()=>closeImgEditor()}>
                            <a className='edit-header-link'><CloseIcon sx={{fontSize:40,color:"white"}}/></a>
                        </div>
                    </div>
                    <div className='edit-image'>
                        <img id='image' src={require(`./img/test-img-${res}-out.png`)}/>
                    </div>
                    <div className='edit-param'>
                        <div className='next-prev'>
                            <a className='reset-btn' title={intl.formatMessage({id:'original_image'})} onClick={()=>{changeimg(false)}}><ArrowBackIcon sx={{fontSize:40,color:"white"}}/></a>
                            <a className='reset-btn' title={intl.formatMessage({id:'result'})} onClick={()=>{changeimg(true)}}><ArrowForwardIcon sx={{fontSize:40,color:"white"}}/></a>
                        </div>
                        <div className='range'>
                            <input id="br" type="range" min="0" defaultValue="100" max="500" onChange={()=>change()}/>
                            <label><FormattedMessage id="brightness"/></label>
                        </div>
                        <div className='range'>
                            <input id='con' type='range' min='0' max='500' defaultValue="100" onChange={()=>change()}/>
                            <label><FormattedMessage id="contrast"/></label>
                        </div>
                        <div className='reset-btn-div'>
                            <a className='reset-btn' title={intl.formatMessage({id:'invert_image'})} onClick={()=>{invert=!invert;change()}}><InvertColorsIcon  sx={{fontSize:40,color:"white"}}/></a>
                        </div>
                        <div className='reset-btn-div'>
                            <a className='reset-btn'title={intl.formatMessage({id:'reset_settings'})} onClick={()=>resetparam()}><RestartAltIcon sx={{fontSize:40,color:"white"}}/></a>
                        </div>  
                    </div>
                </div>
            )}
            <div className='main-top' id='main-container'>
                <div className='main-header'>
                    <div className='main-header-head'>
                        {localStorage.getItem('locale')=='ru-RU'||localStorage.getItem('locale')==null?
                            (
                            <div className='main-header-language' onClick={()=>transreload('en-US')}>
                                <img className='lang-img' src={en} alt=""/>
                            </div>
                            ):(
                            <div className='main-header-language' onClick={()=>transreload('ru-RU')}>
                                <img className='lang-img' src={rus} alt=""/>
                            </div>)}
                        <div className='main-header-auth'><Link to='/login' className='auth-button'><button className='auth-button'><FormattedMessage id="main_auth_btn"/></button></Link></div>
                    </div>
                    <div className='main-header-nav'>
                        <div className='main-header-link'>
                        <Lnk className='link' to="/main"><FormattedMessage id="allnews_link_main"/></Lnk>
                        <Lnk className='link' to="/about"><FormattedMessage id="main_link_about"/></Lnk>
                        <Lnk className='link' to="/news/"><FormattedMessage id="main_link_news"/></Lnk>
                        {/* <Lnk className='link' to="/main/#test"><FormattedMessage id="main_link_test"/></Lnk> */}
                        <Lnk className='link' to="/main/#partner"><FormattedMessage id="main_link_partner"/></Lnk>
                        <Lnk className='link' to="/main/#contact"><FormattedMessage id="main_link_contacts"/></Lnk>
                        </div>
                    </div>
                </div>
                <div className='main-part'>
                    <div className='main-logo'>
                        <div className='imag'>
                            <img src={localStorage.getItem('locale')=='ru-RU'?ftz:ftz_en} alt=""/>
                        </div>
                    </div>
                    <div className='main-title'> <div className='title-text'><FormattedMessage id="main_logo_title"/></div></div>
                    <div className='main-text' id='main-text'><FormattedMessage id="main_logo_subtitle"/></div>
                    <div className='itstars' id='itstars'><div className='itst'><img src={require('../../images/Itstars.png')} alt=""/></div></div>
                    <div className='mercury' id='mercury'><div className='merc'><img src={require('../../images/mercury.png')} alt=""/></div></div>
                    <div className='test-block'>
                        <div className='test-block-title'><FormattedMessage id="success"/></div>
                        <div className='test-block-btn'>
                            <a className='test-page-button'><button onClick={()=>{openImgEditor()}}><FormattedMessage id="test_result"/></button></a>
                            <Link to='/' className='test-page-button'><button><FormattedMessage id="allnews_link_main"/></button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testpage;