import Admin from "./pages/Admin"
import User from "./pages/User"
import About from "./pages/About"
import Auth from "./pages/Auth"
import Main from "./pages/Main"
import Allnews from "./pages/Allnews"
import News1 from './pages/News/News1'
import News2 from './pages/News/News2'
import News3 from './pages/News/News3'
import News4 from './pages/News/News4'
import Testpage from "./pages/subcomponents/Testpage"
export const authRoutes = [
    {
        path:'/',
        component:Main,
    },
    {
        path:'/main',
        component:Main,
    },
    {
        path:'/test/:id',
        component:Testpage,
    },
    {
        path:'user/*',
        component:User,
    },
    {
        path:'/news/1',
        component:News1,
    },
    {
        path:'/news/2',
        component:News2,
    },
    {
        path:'/news/3',
        component:News3,
    },
    {
        path:'/news/4',
        component:News4,
    },
    {
        path:'admin/*',
        component:Admin,
    },
    {
        path:'/about',
        component:About,
    },
    {
        path:'login',
        component:Auth,
    },
    {
        path:'news/',
        component:Allnews,
    }
]
export const publicRoutes = [
    {
        path:'/',
        component:Main,
    },
    {
        path:'/main',
        component:Main,
    },
    {
        path:'/news/1',
        component:News1,
    },
    {
        path:'/news/2',
        component:News2,
    },
    {
        path:'/test/:id',
        component:Testpage,
    },
    {
        path:'/news/3',
        component:News3,
    },
    {
        path:'/news/4',
        component:News4,
    },
    {
        path:'/about',
        component:About,
    },
    {
        path:'/login',
        component:Auth,
    },
    {
        path:'/news',
        component:Allnews,
    }
]