import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress from '@mui/material/CircularProgress';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CompareIcon from '@mui/icons-material/Compare';
import {FormattedMessage,useIntl} from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import Cookies from 'js-cookie';

import './research.css'

const address = 'https://ftizisbiomed.ru'

const Research = () => {
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);
    const intl = useIntl()
    const [file,Setfile]=useState("")
    const token = Cookies.get('_auth')
    const [url,setUrl]=useState('')
    const [originalUrl,setOriginalUrl] = useState('')
    const [reslUrl,setresUrl] = useState('')
    const [loading,setLoading]=useState(false)
    var invert = false;   

    const openImgEditor = () => {
        setIsImgEditorShown(true);
    };

    const closeImgEditor  = () => {setIsImgEditorShown(false);};

    const handleChange =()=>{Setfile(document.getElementById("file").files[0].name)}

    const resetparam = () => {
        document.getElementById('con').value=100;
        document.getElementById('br').value=100;
        invert=false
        document.getElementById('image').style.filter = "brightness(100%) contrast(100%)";
    }

    const change = () => {
        var b = document.getElementById('br').value;
        var c = document.getElementById('con').value;
        document.getElementById('image').style.filter="brightness("+b+"%) contrast("+c+"%) invert(0%)"
        if(invert)document.getElementById('image').style.filter+=" invert(100%)"
    }

    const sendImage = () =>{
        if(!document.getElementById('file').value){alert(intl.formatMessage({id:'click_browse'}));return 0}
        let url = URL.createObjectURL(document.getElementById('file').files[0])
        setOriginalUrl(url)
        setLoading(true)
        const fd = new FormData()
        fd.append('file',document.getElementById('file').files[0])
        fetch(address+'/predict/', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer '+token,
            'SOP':'file: '+file+' (On-line proccessing)'
        },
        body:fd
        }).then(resp=>{if (resp.ok) return resp;else {if(resp.status==422)alert(intl.formatMessage({id:'no_legkie'}));setLoading(false);return 0}}).then((response) => {
            if(response!=false) {const reader = response.body.getReader();
            return new ReadableStream({
              start(controller) {
                return pump();
                function pump() {
                  return reader.read().then(({ done, value }) => {
                    if (done) {
                      controller.close();
                      return;
                    }
                    controller.enqueue(value);
                    return pump();
                  });
                }
              }
            })}
            else {setLoading(false); return 0}
          }).then((stream) => new Response(stream)).then((response) => response.blob()).then((blob) => URL.createObjectURL(blob))
          .then((url) => {
            setUrl(url)
            setresUrl(url)
            setLoading(false)
            setTimeout(()=>document.getElementById('img').src = url,200)
        }).catch((err) => alert(err.status));
    }
    return (
        <div className='user-research'>
            {isImgEditorShown && (
                <div className='edit-div'>
                    <div className='edit-header'>
                        <div className='edit-header-btn' onClick={()=>closeImgEditor()}>
                            <a className='edit-header-link'><CloseIcon sx={{fontSize:40,color:"white"}}/></a>
                        </div>
                    </div>
                    <div className='edit-image' id='edit-image'>
                        <img id='image' src={url}/>
                    </div>
                    <div className='edit-param'>
                        <div className='next-prev'>
                            <a className='reset-btn' title={intl.formatMessage({id:'original_image'})} onClick={()=>{setUrl(originalUrl)}}><ArrowBackIcon sx={{fontSize:40,color:"white"}}/></a>
                            <a className='reset-btn' title={intl.formatMessage({id:'result'})} onClick={()=>{setUrl(reslUrl)}}><ArrowForwardIcon sx={{fontSize:40,color:"white"}}/></a>
                        </div>
                        <div className='range'>
                            <input id="br" type="range" min="0" defaultValue="100" max="500" onChange={()=>change()}/>
                            <label><FormattedMessage id="brightness"/></label>
                        </div>
                        <div className='range'>
                            <input id='con' type='range' min='0' max='500' defaultValue="100" onChange={()=>change()}/>
                            <label><FormattedMessage id="contrast"/></label>
                        </div>
                        <div className='reset-btn-div'>
                            <a className='reset-btn' title={intl.formatMessage({id:'invert_image'})} onClick={()=>{invert=!invert;change()}}><CompareIcon  sx={{fontSize:40,color:"white"}}/></a>
                        </div>
                        <div className='reset-btn-div'>
                            <a className='reset-btn'title={intl.formatMessage({id:'reset_settings'})} onClick={()=>resetparam()}><RestartAltIcon sx={{fontSize:40,color:"white"}}/></a>
                        </div>  
                    </div>
                </div>
            )}
            <div className='input-file'>
                <div className='input-file-title'>
                    <div className='input-file-title-text'><FormattedMessage id="user_online_proccessing_select"/></div>
                </div>
                <div className='input-file-field'>
                    <input id="file" type="file" onChange={()=>handleChange()}/>
                    <label htmlFor="file">
                        {file==="" ? intl.formatMessage({id:'user_online_proccessing_file'}):file}
                    </label>
                </div>
                <div className='input-file-btn'>
                    <button onClick={()=>sendImage()}><FormattedMessage id="user_online_proccessing_send"/></button>
                </div>
            </div>
            <div className='output-file'>
                <div className='output-file-title'>
                    <FormattedMessage id="user_online_proccessing_result"/>
                </div>
                <div className='output-file-field'>
                    {loading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <img id='img' src='' alt='' onClick={()=>openImgEditor()}/>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Research;