import {FormattedMessage} from 'react-intl';
import { Link } from 'react-router-dom';
import React from 'react';
import './news.css'

const Newscard = (props) => {
    const path = props.id
    return (
        <div className='news-card' id='newsid'>
            <div className='news-card-img'>
                <img src={require(`./img/${props.img}`)} alt=""/>
            </div>
            <div className='news-card-text'>{props.text}</div>
            <div className='news-card-btn'><Link to={path}><button><FormattedMessage id="allnews_link_more"/></button></Link></div>
        </div>
    );
};

export default Newscard;