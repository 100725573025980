import React from 'react';
import { Link } from 'react-router-dom';
import './news.css'

const New = (props) => {
    const path = '/news/'+props.id
    const id = 'news-'+props.id
    return (
        <div className='news-form' id={id}>
            <Link to={path}>
                <div className='news-img'>
                    <img src={require(`./img/${props.img}`)} alt=""/>
                </div>
                <div className='news-text'>{props.text}</div>
            </Link>
        </div>
    );
};

export default New;