import React, { useState,useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'js-cookie';
import "./adminusers.css"

const address = 'https://ftizisbiomed.ru/api'

const Adminusers = () => {
    const [edit,setEdit]=useState(false)
    const [users,setUsers] = useState([])
    const [loading,setLoading] = useState(true)
    const token = Cookies.get('_auth')
    const [user,Setuser]=useState({
        id:0,
        login:'',
        price:'',
        status:'',
        role:'',
        email:'',
        organization:'',
        password:'',
    })
    useEffect(()=>{
        fetch(address+'/get/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        }
        }).then(response=>{if(response.ok) return response.json();else return false}).
        then(res=>{
            if(res!=false){
                setUsers(res)
            }
            setLoading(false)
        })
    },[])
    const confirmDelete = (id) => {
        let confirm = window.confirm('Удалить данного пользователя?')
        if (confirm==true)
        {   
            setLoading(true)
            fetch(address+'/delete/user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
            },
            body:'{"user_id":'+id+'}'
        }).then(response=>{if(response.ok) return response.json();else return false}).
        then(res=>{
            if(res!=false){
                setUsers(res)
            }
             setTimeout(() => {
                setLoading(false)
             }, 200);
        })}
        else return 0
    }
    const setdata = (data) => {
        setEdit(true)
        Setuser({...user,
            ['id']:data[0],
            ['login']:data[1],
            ['organization']:data[2],
            ['status']:data[3],
            ['role']:data[4],
            ['email']:data[5]
        })
    }
    const text = (text) => {
        return (
            <div className='admin-users-form-input-text-block'>
                {text}
            </div>
        )
    }
    const saveUser = () =>{ 
        setLoading(true)
        fetch(address+'/update/user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
            },
            body:'{"user_id":' +user.id+',"login": "'+user.login+'","email":"'+user.email+'","status":'+user.status+',"role": "'+user.role+'"}'
        }).then(response=>{if(response.ok) return response.json();else return false}).
        then(res=>{
            if(res!=false){
                setUsers(res)
            }
        })
        setLoading(false)
        Setuser({...user,
            ['id']:null,
            ['login']:'',
            ['organization']:'',
            ['status']:'',
            ['role']:''
        })
        setEdit(false)
        
    }
    const clearFrom = ()  =>{
        console.log(user)
        Setuser({...user,
            ['id']:null,
            ['login']:'',
            ['organization']:'',
            ['status']:'',
            ['role']:'',
            ['password']:''
        })
        setEdit(false)
    }       
    const handle = (e) => {
        Setuser({
          ...user,
          [e.target.name]: e.target.value,
        });
    }
    const createUser = () => {
        if(document.getElementById('login').value.length<3){alert ('Логин должен содержать мимнимум 3 символа');return 0;}
        console.log(document.getElementById('password').value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_]).{8,}$/))
        if(document.getElementById('password').value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_]).{8,}$/)==null){alert ('Пароль должен быть больше 8 символов и содержать:\nЗаглавные и строчные латинские буквы\nМинимум 1 цифру\nМинимум 1 специальный символ (!@#$%&*_{})');return 0;}
        setLoading(true)
        fetch('https://ftizisbiomed.ru/register/user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:'login='+user.login+'&password='+user.password+'&role='+user.role+'&organization='+user.organization+'&status='+user.status}).then(resp=>{if(resp.ok) {return resp.json()} else return false}).then(result=>{if (result!==false) setUsers(result[1]); setLoading(false)})
    }
    return (
        <div className='admin-users'>
            <div className='admin-users-table'>
                <div className='admin-users-table-data'>
                    {loading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <table className='admin-user-table'>
                            <thead className='ad-user-thead'>
                                <tr className='ad-user-tr'>
                                    <th className='ad-user-th'>Логин</th>
                                    <th className='ad-user-th'>Организация</th>
                                    <th className='ad-user-th'>Дата создания</th>
                                    <th className='ad-user-th'>Статус</th>
                                    <th className='ad-user-th'>Роль</th>
                                    <th className='ad-user-th' colSpan='2'>Действия</th>
                                </tr>
                            </thead>
                            <tbody className='ad-user-tbody'>
                                {users.length==0&&
                                    <tr className='ad-user-tr'>
                                        <td className='ad-user-td' colSpan='6'>Нет данных</td>
                                    </tr>
                                }
                                {users.map((item)=>
                                    <tr className='ad-user-tr' key={item.id}>
                                        <td className='ad-user-td'>{item.login}</td>
                                        <td className='ad-user-td'>{item.organization}</td>
                                        <td className='ad-user-td'>{item.datetime_creation}</td>
                                        <td className='ad-user-td'>{item.is_active?'активен':'не активен'}</td>
                                        <td className='ad-user-td'>{item.role}</td>
                                        <td className='ad-user-td-btn'><button className='ad-user-edit' onClick={()=>setdata([item.id,item.login,item.organization,item.is_active,item.role,item.email])}>Редактировать</button></td>
                                        <td className='ad-user-td-btn'><button className='ad-user-delete' onClick={()=>confirmDelete(item.id)}>Удалить</button></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
            <div className='admin-users-form'>
                <div className='admin-users-form-title'>
                    Создание/редактирование пользователя
                </div>
                <div className='admin-users-form-input'>
                    <div className='admin-users-form-input-text'>
                        {text("Логин")}
                        {!edit?text("Пароль"):text("E-mail")} 
                        {text("Организация")} 
                        {text("Статус")} 
                        {text("Роль")}
                    </div>
                    <div className='admin-users-form-input-field'>
                        <div className='admin-users-form-input-text-block'>
                            <input type="text" name="login" id='login' value={user.login} onChange={handle}/>
                        </div>
                        {!edit?(<div className='admin-users-form-input-text-block'>
                            <input type="text" name="password" id='password' value={user.password} onChange={handle}/>
                        </div>):(<div className='admin-users-form-input-text-block'>
                            <input type="text" name="email" value={user.email} onChange={handle}/>
                        </div>)}
                        <div className='admin-users-form-input-text-block'>
                            <input type="text" name="organization" value={user.organization} onChange={handle}/>
                        </div>
                        <div className='admin-users-form-input-text-block'>
                            <select name='status' onChange={handle} value={user.status}>
                                <option value = ''></option>
                                <option value = {true}>активен</option>
                                <option value = {false}>не активен</option>
                            </select>
                        </div>
                        <div className='admin-users-form-input-text-block'>
                            <select name='role' onChange={handle} value={user.role}>
                                <option value = ''></option>
                                <option value = 'user'>пользователь</option>
                                <option value = 'admin'>администратор</option>
                            </select>
                        </div>
                        
                    </div>
                </div>
                {edit?(<div className='admin-users-form-button'>
                    <button className='clear-form-btn' onClick={()=>clearFrom()}>Очистить форму</button>
                    <button onClick={()=>saveUser()}>Сохранить</button>
                </div>):
                (<div className='admin-users-form-button'>
                    <button className='clear-form-btn' onClick={()=>clearFrom()}>Очистить форму</button>
                    <button onClick={()=>createUser()}>Создать</button>
                </div>)}
            </div>
        </div>
    );
};

export default Adminusers;