import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSignOut } from 'react-auth-kit';
import {useAuthUser} from 'react-auth-kit';
import rus from '../../images/lang-ru.png'
import en from '../../images/lang-en.png'
import moment from 'moment';

import './navbar.css'

const Navbar = () => {
  const signOut = useSignOut()
  const [loading,setLoading] = useState(true)
  const navigate = useNavigate()
  const user = useAuthUser()
  const logOut = () => {
    navigate('/login')
    signOut()
  }
  const transreload = (locale) => {
    localStorage.setItem('locale',locale)
    window.location.reload()
}
  useEffect(()=>{
    setLoading(false)
  },[])
  return (
    <div className="navbar">
      {loading?(
        <div className="user-data">
          <CircularProgress sx={{color:'white'}}/>
        </div>
      ):(
        <div className="user-data">
        <div className="data-city">
          <div className="data">
            {moment().format('DD.MM.YYYY')}
          </div>
          <div className="city">
            {/* {user().address} */}
          </div>
        </div>  
        <div className="user-priority">
          <div className="user">
            {user().login}
          </div>
          <div className="priority">
            {user().role=='admin'? (localStorage.getItem('locale')=='ru-RU'?"Администратор":"Admin"):(localStorage.getItem('locale')=='ru-RU'?"Пользователь":"User")}
          </div>
        </div>
        <div className="user-icon">
          {user().role=='admin'? (<AccountCircleIcon fontSize='large'/>):
            localStorage.getItem('locale')=='ru-RU'||localStorage.getItem('locale')==null?
            (
            <div className='main-header-language' onClick={()=>transreload('en-US')}>
                <img className='lang-img' src={en} alt=""/>
            </div>
            ):(
            <div className='main-header-language' onClick={()=>transreload('ru-RU')}>
                <img className='lang-img' src={rus} alt=""/>
            </div>)}
        </div>
        <div className="exit-icon">
          <a onClick={() => logOut()}><ExitToAppOutlinedIcon sx={{fontSize:40}} /></a>
        </div>
      </div>
      )} 
    </div>
  );
};

export default Navbar;
