import { HashLink as Lnk } from 'react-router-hash-link';
import {FormattedMessage,useIntl} from 'react-intl';
import Newscard from './subcomponents/Newscard';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import '../styles/allnews.css'


const Allnews = () => {
    const intl = useIntl()
    useEffect(()=>window.scrollTo({top: 0}),[])
    const transreload = (locale) => {
        localStorage.setItem('locale',locale)
        window.location.reload()
    }
    return (
        <div className='all-news-page'>
            <div className='news-page-header'>
                <div className='main-header-head'>
                    {localStorage.getItem('locale')=='ru-RU'?
                        (<div className='main-header-language' onClick={()=>transreload('en-US')}>
                            <img className='lang-img' src={require('../images/lang-en.png')} alt=""/>
                        </div>
                        ):(<div className='main-header-language' onClick={()=>transreload('ru-RU')}>
                            <img className='lang-img' src={require('../images/lang-ru.png')} alt=""/>
                        </div>)}
                        <div className='main-header-auth'><Link to='/login' className='auth-button'><button className='auth-button'><FormattedMessage id="main_auth_btn"/></button></Link></div>
                </div>
                <div className='main-header-nav'>
                    <div className='main-header-link'>
                        <Lnk className='link' to="/main"><FormattedMessage id="allnews_link_main"/></Lnk>
                        <Lnk className='link' to="/about"><FormattedMessage id="main_link_about"/></Lnk>
                        <Lnk className='link' to="/main/#test"><FormattedMessage id="main_link_test"/></Lnk>
                        <Lnk className='link' to="/main/#partner"><FormattedMessage id="main_link_partner"/></Lnk>
                        <Lnk className='link' to="/main/#contact"><FormattedMessage id="main_link_contacts"/></Lnk>                    
                    </div>
                </div>
                <div className='news-page-title'><FormattedMessage id="main_news_title"/></div>
            </div>
            <div className='news-page-content'>
                <div className='news-content-cards'>
                    <Newscard img='news-1.png' text = {intl.formatMessage({id:'main_news_1_title'})} id='1'/>
                    <Newscard img='news-2.png' text = {intl.formatMessage({id:'main_news_2_title'})} id='2'/>
                    <Newscard img='news-3.png' text = {intl.formatMessage({id:'main_news_3_title'})} id='3'/>
                    <Newscard img='news-4.png' text = {intl.formatMessage({id:'main_news_4_title'})} id='4'/>
                </div>
            </div>
            {(document.documentElement.scrollWidth>500)?(
                    <div className='about-footer'>
                        <div className='about-footer-content'>
                            <div className='about-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='about-social'>
                                <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/vk.png')} alt=""/></a>
                                <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/tel.png')} alt=""/></a>
                            </div>
                            <div className='about-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                ):(
                    <div className='about-footer'>
                        <div className='about-footer-content'>
                            <div className='about-social'>
                                    <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/vk.png')} alt=""/></a>
                                    <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/tel.png')} alt=""/></a>
                                </div>
                            <div className='about-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='about-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                )}
        </div>
    );
};

export default Allnews;