import {React} from 'react';
import '../styles/Main.css'
import { Link } from 'react-router-dom';
import Test from './subcomponents/Test';
import Partner from './subcomponents/Partner';
import Prize from './subcomponents/Prize';
import Contact from './subcomponents/Contact'
import ftz from '../images/ftz.svg'
import ftz_en from '../images/ftz_en.svg'
import New from './subcomponents/New';
import { useEffect,useState} from 'react';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {FormattedMessage,useIntl} from 'react-intl';
import rus from '../images/lang-ru.png'
import eng from '../images/lang-en.png'


const Main = () => {
    const intl = useIntl()
    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
          if (window.pageYOffset > 600) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        });
      }, []);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        console.log('hello')
    };  
    const transreload = (locale) => {
        localStorage.setItem('locale',locale)
        window.location.reload()
    }
    return (
        <div className='main'>
            <div className='main-top' id='main-container'>
                {showButton && (
                    <div onClick={scrollToTop} className="back-to-top">
                        <a rel="noreferrer"><ArrowUpwardOutlinedIcon sx={{fontSize:50,color:'white'}}/></a>
                    </div>
                )}
                <div className='main-header'>
                    <div className='main-header-head'>
                        {localStorage.getItem('locale')=='ru-RU'||localStorage.getItem('locale')==null?
                            (
                            <div className='main-header-language' onClick={()=>transreload('en-US')}>
                                <img className='lang-img' src={eng} alt=""/>
                            </div>
                            ):(
                            <div className='main-header-language' onClick={()=>transreload('ru-RU')}>
                                <img className='lang-img' src={rus} alt=""/>
                            </div>)}
                        <div className='main-header-auth'><Link to='/login' className='auth-button'><button className='auth-button'><FormattedMessage id="main_auth_btn"/></button></Link></div>
                    </div>
                    <div className='main-header-nav'>
                        <div className='main-header-link'>
                            <Link className='link' to="/about"><FormattedMessage id="main_link_about"/></Link>
                            <Link className='link' to="/news/"><FormattedMessage id="main_link_news"/></Link>
                            <a className='link' href='#test' rel="noreferrer"><FormattedMessage id="main_link_test"/></a>
                            <a className='link' href='#partner' rel="noreferrer"><FormattedMessage id="main_link_partner"/></a>
                            <a className='link' href='#contact' rel="noreferrer"><FormattedMessage id="main_link_contacts"/></a>
                        </div>
                    </div>
                </div>
                <div className='main-part'>
                    <div className='main-logo'><div className='imag'><img src={localStorage.getItem('locale')=='ru-RU'?ftz:ftz_en} alt=""/></div></div>
                    <div className='main-title'> <div className='title-text'><FormattedMessage id="main_logo_title"/></div></div>
                    <div className='main-text'><FormattedMessage id="main_logo_subtitle"/></div>
                    <div className='itstars'><div className='itst'><img src={require('../images/Itstars.png')} alt=""/></div></div>
                    <div className='mercury'><div className='merc'><img src={require('../images/mercury.png')} alt=""/></div></div>
                    {(document.documentElement.scrollWidth>500)?(<div className='version'>
                        <FormattedMessage id="main_version"/><br/>
                        <FormattedMessage id="main_update"/><br/>
                        <FormattedMessage id="main_registr"/><br/>
                        <FormattedMessage id="main_entry"/><br/>
                    </div>
                    ):(
                        <div className='version'>
                        <FormattedMessage id="main_version"/>{". "} 
                        <FormattedMessage id="main_update"/><br/>
                        <FormattedMessage id="main_registr"/>{". "} 
                        <FormattedMessage id="main_entry"/><br/>
                    </div>)}
                </div>
            </div>
            <div className='main-news'>
                <div className='news-content'>
                    <div className='news-title'><Link to='/news/'><FormattedMessage id="main_news_title"/></Link></div>
                    <New img='news-1.png' text = {intl.formatMessage({id:'main_news_1_title'})} id='1'/>
                    <New img='news-2.png' text = {intl.formatMessage({id:'main_news_2_title'})} id='2'/>
                    <New img='news-3.png' text = {intl.formatMessage({id:'main_news_3_title'})} id='3'/>
                    <New img='news-4.png' text = {intl.formatMessage({id:'main_news_4_title'})} id='4'/>
                </div>
            </div>
            <div className='main-test' id='test'>
                <div className='test-content'>
                    <div className='test-title'><FormattedMessage id="main_test_title"/></div>
                    <Test text={intl.formatMessage({id:'main_test_1_text'})} image='test-img-1.png' id="test-1" identif='1'/>
                    <Test text={intl.formatMessage({id:'main_test_2_text'})} image='test-img-2.png' id="test-2" identif='2'/>
                    <Test text={intl.formatMessage({id:'main_test_3_text'})} image='test-img-3.png' id="test-3" identif='3'/>
                    <Test text={intl.formatMessage({id:'main_test_4_text'})} image='test-img-4.png' id="test-4" identif='4'/>
                    <Test text={intl.formatMessage({id:'main_test_5_text'})} image='test-img-5.png' id="test-5" identif='5'/> 
                </div>
            </div>
            <div className='main-partner' id='partner'>
                <div className='partner-content'>
                    <div className='partner-title'><FormattedMessage id="main_partner_title"/></div>
                    <Partner text = {intl.formatMessage({id:'main_partner_1_title'})} id="partner-1" img ="radiology.png" link = "https://tele-med.ai/"/>
                    <Partner text = {intl.formatMessage({id:'main_partner_2_title'})} id="partner-2" img ="skolkovo.png" link = "https://sk.ru/"/>
                    <Partner text = {intl.formatMessage({id:'main_partner_3_title'})} id="partner-3" img ="skolkovo.png" link = "https://www.pulmonology-russia.ru/"/>
                    <Partner text = {intl.formatMessage({id:'main_partner_4_title'})} id="partner-4" img ="minzdrav.png" link = "https://rkpd.tatarstan.ru/"/>
                    <Partner text = {intl.formatMessage({id:'main_partner_5_title'})} id="partner-5" img ="bortnik.png" link = "https://fasie.ru/"/>
                </div>
            </div>
            <div className='main-prize' id='prize'>
                <div className='prize-content'>
                    <div className='prize-title'><FormattedMessage id="main_awards_title"/></div>
                    <Prize id="prize-1" text={intl.formatMessage({id:'main_awards_1_text'})} img ="Itstars.png"/>
                    <Prize id="prize-2" text={intl.formatMessage({id:'main_awards_2_text'})} img ="mercury.png"/>
                </div>
            </div>
            <div className='main-contact' id='contact'>
                <div className='contact-content'>
                    <div className='contact-content-title'><FormattedMessage id="main_contacts_title"/></div>
                    <Contact id="contact-1" title = {intl.formatMessage({id:'main_contacts_address_title'})} text={intl.formatMessage({id:'main_contacts_address_text'})} component="TelegramIcon"/>
                    <Contact id="contact-2" title= {intl.formatMessage({id:'main_contacts_phone_title'})} text="+7(84342) 5 55 55" component="TelegramIcon"/>
                    <Contact id="contact-3" title = 'E-MAIL:' text='info@ftizisbiomed.ru' component="TelegramIcon"/>
                </div>
                <div className='ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                <div className='social'>
                    <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/vk.png')} alt=""/></a>
                    <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/tel.png')} alt=""/></a>
                </div>
                <div className='vector-info'><FormattedMessage id="main_vector"/></div>
            </div>
        </div>
    );
}   

export default Main;