import React, { useState } from 'react';
import { Routes,Route } from 'react-router-dom';
import Adminmain from '../Admin/Adminmain';
import Adminnews from '../Admin/Adminnews';
import Adminnewspage from '../Admin/Adminnewspage';
import Adminstat from '../Admin/Adminstat';
import Adminusers from '../Admin/Adminusers';
import Navbar from './subcomponents/Navbar';
import Sidebar from './subcomponents/sidebar';
import MenuIcon from '@mui/icons-material/Menu';
import {useAuthUser} from 'react-auth-kit';


import './subcomponents/sidebar.css'

const Admin = () => {
    const [sidebar,Setsidebar]=useState(true)
    const change = () => {
        Setsidebar(true)
        if(sidebar===true)document.getElementById('sidebar').style.left="0px";
        if(document.documentElement.scrollWidth<1100)document.getElementById('sidebar').style.left="0px";
    }
    const user = useAuthUser()
    return (
        <div className='admin-page'>
            <div className='sidebar-menu-btn'>
                <a onClick={()=>change()}><MenuIcon sx={{ fontSize: 30 }}/></a>
            </div>
            <Sidebar/>
            <div className='admin-page-part'>
                <Navbar/>
                <div className='admin-page-content'>
                    <Routes>
                        <Route path="adminmain" element={<Adminmain/>}/>
                        <Route path="adminstat/*" element={<Adminstat/>}/>
                        <Route path="adminusers" element={<Adminusers/>}/>
                        <Route path="adminnews" element={<Adminnews/>}/>
                        <Route path="adminnews/*" element={<Adminnewspage/>}/>
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default Admin;