import React from 'react';
import './partner.css'
import nii from './img/nii.svg'
const Partner = (props) => {
    return (
        <div className='partner-form' id={props.id}>
            <a href={props.link} target="_blank" rel="noreferrer">
                <div className='partner-img'>
                    {props.id==="partner-3"?(<img src={nii} alt=""/>):(<img src={require(`./img/${props.img}`)} alt=""/>)}
                </div>
                <div className='partner-text'>{props.text}</div>
            </a>
        </div>
    );
};

export default Partner;