import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect,useState } from 'react';
import {FormattedMessage,useIntl} from 'react-intl';
import { CategoryScale } from 'chart.js'; 
import { Bar } from 'react-chartjs-2'
import Chart from 'chart.js/auto';
import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/ru';
import './stats.css'

const address = 'https://ftizisbiomed.ru/api'
Chart.register(CategoryScale);

const Stats = () => {
    const [selectloading,setSelectLoading] = useState(true)
    const [KeyArray,setKeyArray] = useState([])
    const [ValArray,setValArray] = useState([])
    const [loading,setLoading] = useState(true)
    const [average, setAverage] = useState(0)
    const [counter,setCounter] = useState(0)
    const [items,setItems]=useState([{}])
    const [downloadState,setDownloadState] = useState(false)
    const [price,setPrice] = useState(0)
    const token = Cookies.get('_auth')
    const intl = useIntl()
    useEffect(()=>{
        document.getElementById('end').value= moment().format('YYYY-MM-DD')
        document.getElementById('start').value= moment().date(1).format('YYYY-MM-DD')
        let date = new Date().toISOString()
        fetch(address+'/get/month/items', { 
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:'{"date":"'+date+'"}'
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
          if(result!==false){
            let final = 7.0
            let res = result[3].filter((item)=>moment(item.datetime).diff(moment(item.send_time))>=0&&item.send_time!==null&&moment(item.datetime).diff(moment(item.send_time))<20000).map((item)=>moment(item.datetime).diff(moment(item.send_time)))
            if(Object.keys(res).length!==0){let sum = res.reduce((a,b)=>(a+b));final=(sum/(Object.keys(res).length*1000)).toFixed(1)}
            setKeyArray(Object.keys(result[0]))
            setValArray(Object.values(result[0]))
            setItems(result[3])
            setCounter(result[1])
            setPrice(result[2].price)
            setSelectLoading(false)
            Object.keys(result[3]).length!==0?setAverage(final):setAverage(0)
          }
            else alert("Неверно введённые данные")
        })
        setLoading(false)
    },[])

    const downloadFile =() => {//метод для скачивания файла с сервера
        if(document.getElementById('start').value===''&&document.getElementById('end').value===''){alert('Задайте конкретный период');return 1;} 
        if(document.getElementById('start').value!==''&&document.getElementById('end').value==='')document.getElementById('end').value= moment().format('YYYY-MM-DD')
        if(document.getElementById('start').value===''&&document.getElementById('end').value!=='')document.getElementById('start').value=moment(document.getElementById('end').value).date(1).format('YYYY-MM-DD')
        if(document.getElementById('start').value>=document.getElementById('end').value)return alert("Неверно указан период (конец периода должен быть больше начала)")
        setDownloadState(true)
        let start = document.getElementById('start').value;
        let end = document.getElementById('end').value;
        fetch(address+'/download/user/statistic',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Authorization': 'Bearer '+token,
            },
            body:'{"id_mas":{},"date_start":"'+start+' 00:00:00","date_end":"'+end+' 23:59:59"}'
        }).then((response) => response.blob()).then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Статистика (${moment(start).format('DD.MM.YYYY')}-${moment(end).format('DD.MM.YYYY')}).csv`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        setDownloadState(false)
    }
    
    const setPeriod = () => {// Отправка даты начала и конца периода для получения исследований
        if(!moment(document.getElementById('start').value).isValid()){alert('Указана несуществующая дата начала периода');return 1;}
        if(!moment(document.getElementById('end').value).isValid()){alert('Указана несуществующая дата конца периода');return 1;}
        if(document.getElementById('start').value===''&&document.getElementById('end').value===''){alert('Задайте конкретный период');return 1;} 
        if(document.getElementById('start').value!==''&&document.getElementById('end').value==='')document.getElementById('end').value= moment().format('YYYY-MM-DD')
        if(document.getElementById('start').value===''&&document.getElementById('end').value!=='')document.getElementById('start').value=moment(document.getElementById('end').value).date(1).format('YYYY-MM-DD')
        if(document.getElementById('start').value>=document.getElementById('end').value)return alert("Неверно указан период (конец периода должен быть больше начала)")
        setLoading(true)
        let start = document.getElementById('start').value;
        let end = document.getElementById('end').value;
        fetch(address+'/get/period/data', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
          },
          body:'{"start_date":"'+start+' 00:00:00","end_date":"'+end+' 23:59:00"}'
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
          if(result!==false){
            let final = 7
            let res = result[2].filter((item)=>item.send_time!==null&&moment(item.datetime).diff(moment(item.send_time))<20000&&moment(item.datetime).diff(moment(item.send_time))>0).map((item)=>moment(item.datetime).diff(moment(item.send_time)))
            if(Object.keys(res).length!==0){let sum = res.reduce((a,b)=>(a+b));final=(sum/(Object.keys(res).length*1000)).toFixed(1)}
            setKeyArray(Object.keys(result[0]))
            setValArray(Object.values(result[0]))
            setCounter(result[1])
            setItems(result[2])
            Object.keys(result[2]).length!==0?setAverage(final):setAverage(0)
            setLoading(false)}
            else alert("Неверно указан период")
        })
    }
    const str = (txt) => { //Отображение текста строк
        return (
            <div className='info-txt'>
                {txt}
            </div>)
    }

    const handleSelect = () => {//Обработчик изменения select компонента
        if (moment(document.getElementById('start').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek')))document.getElementById('select').value='current_week'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('month').add(-1,'day'),moment().endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('month'),moment().endOf('month')))document.getElementById('select').value='current_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'month').startOf('month').add(-1,'day'),moment().add(-1,'month').endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')))document.getElementById('select').value='previous_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter')))document.getElementById('select').value='current_quarter'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter')))document.getElementById('select').value='previous_quarter'
        else document.getElementById('select').value='random'
    }

    const handleChange = () => { // Обработчик события изменения селектора выбора периода времени
        switch(document.getElementById('select').value){
            case 'default':
                break;
            case 'current_week':
                document.getElementById('start').value=moment().clone().startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().clone().endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'current_month':
                document.getElementById('start').value=moment().startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('month').format('YYYY-MM-DD');
                break;
            case 'previous_month':
                document.getElementById('start').value=moment().add(-1,'month').startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'month').endOf('month').format('YYYY-MM-DD');
                break;
            case 'current_quarter':
                document.getElementById('start').value=moment().startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'previous_quarter':
                document.getElementById('start').value=moment().add(-1,'quarter').startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'quarter').endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'current_year':
                document.getElementById('start').value=moment().startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('year').format('YYYY-MM-DD');
                break;
            case 'previous_year':
                document.getElementById('start').value=moment().add(-1,'year').startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'year').endOf('year').format('YYYY-MM-DD');
                break;
            default:break
        }
        setPeriod()
    }
    return (
        <div className='stat-main'>
            <div className='stat-main-info'>
                <div className='stat-input'>
                    <div className='stat-input-field'><input type='date' max="2999-12-31" id='start' onChange={()=>handleSelect()}></input></div>
                    <div className='stat-input-field'><input type='date' max="2999-12-31" id='end' onChange={()=>handleSelect()}></input></div>
                    <div className='stat-input-button'>
                        {selectloading?(
                            <div className='div-loading'>
                                <CircularProgress sx={{color:'white'}}/>
                            </div>
                            ):(
                            <select id='select' onChange={()=>handleChange()}>
                                <option value='default'><FormattedMessage id="user_stat_select_0"/></option>
                                <option value='current_week'><FormattedMessage id="user_stat_select_1"/></option>
                                <option value='current_month'><FormattedMessage id="user_stat_select_2"/></option>
                                <option value='previous_month'><FormattedMessage id="user_stat_select_3"/></option>
                                <option value='current_quarter'><FormattedMessage id="user_stat_select_4"/></option>
                                <option value='previous_quarter'><FormattedMessage id="user_stat_select_5"/></option>
                                <option value='current_year'><FormattedMessage id="user_stat_select_6"/></option>
                                <option value='previous_year'><FormattedMessage id="user_stat_select_7"/></option>
                                <option value='random'><FormattedMessage id="user_stat_select_8"/></option>
                            </select>)}
                    </div>
                    <div className='stat-input-button'><button  onClick={()=>{setPeriod()}}><FormattedMessage id="display"/></button></div>
                </div>
                <div className='stat-input-diagram'>
                    <div className='stat-diagram'>
                    {loading?
                        (<div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>):(
                        <Bar style={{background:'white',borderRadius:10,padding:10}}
                            data={{
                            labels: moment(KeyArray[0]).year()===moment(KeyArray[KeyArray.length]).year()? KeyArray.map((val)=>{return moment(val).format('DD.MM')}):KeyArray.map((val)=>{return moment(val).format('DD.MM.YY')}),
                            datasets: [
                                {
                                data: ValArray,
                                borderColor:'#000fff',
                                borderWidth:0.7,
                                hoverBackgroundColor:'#006ba6',
                                backgroundColor:'#63c7ff',
                                }, 
                            ],
                            }}
                            options={{
                                interaction: {
                                    intersect: false,
                                    mode:'index'
                                },
                                scales: {
                                    yAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:intl.formatMessage({id:'studies'})
                                        },
                                    },
                                    xAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:intl.formatMessage({id:'date'})
                                        },
                                    }
                                },
                                plugins: {
                                    legend: {
                                        display: false,
                                        labels: {
                                            display: false
                                        }
                                    },
                                }
                            }}
                        />)}
                    </div>
                </div>
                {loading?(<div className='loading-div-block'>
                    <div className='div-loading'>
                        <CircularProgress sx={{color:'white'}}/>
                    </div>
                </div>):
                (<div className='stat-info-text'>
                    {str(intl.formatMessage({id:'user_main_stat_max'})+": "+ Math.max(...ValArray))}
                    {str(intl.formatMessage({id:'user_main_stat_min'})+" "+Math.min(...ValArray))}
                    {str(intl.formatMessage({id:'user_main_stat_total'})+": "+counter)}
                    <div className='info-txt'>
                        {intl.formatMessage({id:'user_main_stat_average'})+": "+average+" "}<FormattedMessage id='sek'/>
                    </div>
                    {str(intl.formatMessage({id:'user_bill_month_bill'})+": "+price*counter+" ₽")}
                </div>)}
                <div className='stat-info-button'>
                    {!downloadState?(<button onClick={()=>downloadFile()}><FormattedMessage id="download_stat_for_period"/></button>):(<CircularProgress sx={{color:'white'}}/>)}
                </div>
            </div>
            <div className='research-table'>
                {loading?(
                    <div className='div-loading'>
                        <CircularProgress sx={{color:'white'}}/>
                    </div>
                    ):(
                    <div className='research-table-data'>
                    <table className='res-table'>
                        <thead id='res-thead'>
                            <tr id='tr-th'>
                                <th className='rs-th'><FormattedMessage id="user_stat_table_id"/></th>
                                <th className='rs-th'><FormattedMessage id="user_stat_table_date"/></th>
                                <th className='rs-th'><FormattedMessage id="user_stat_proccessing_time"/></th>
                                <th className='rs-th'><FormattedMessage id="user_stat_table_probability"/></th>
                                <th className='rs-th'><FormattedMessage id="user_stat_table_classes"/></th>
                            </tr>
                        </thead>
                        <tbody className='res-tbody'>
                            {items.length==0&&(
                                <tr>
                                    <td className='rs-td' colSpan='5'><FormattedMessage id="no_studies"/></td>
                                </tr>)}
                            {items.map((item)=>
                                <tr key={item.id} className='tr-td'>
                                    <td className='rs-td'>{item.sopinstanceuid}</td>
                                    <td className='rs-td'>{moment(item.datetime).format('DD.MM.YYYY HH:mm:ss')}</td>
                                    <td className='rs-td'>{(item.send_time!==null && moment(item.datetime).diff(moment(item.send_time))<20000 && moment(item.datetime).diff(moment(item.send_time))>0)?((moment(item.datetime).diff(moment(item.send_time))/1000).toFixed(1)):(7)}</td>
                                    <td className='rs-td'>{item.prob}</td>
                                    <td className='rs-td'>{item.classes}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>      
                </div>)}
            </div>
        </div>
    );
};

export default Stats;