import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import GroupIcon from '@mui/icons-material/Group';
export const AdminSidebarData = [
  {
    title: "Главная",
    path: "adminmain",
    icon:<HomeOutlinedIcon fontSize="large" />
  },
  {
    title: "Статистика",
    path: "adminstat",
    icon:<QueryStatsIcon fontSize="large" />
  },
  {
    title: "Пользователи",
    path: "adminusers",
    icon:<GroupIcon fontSize="large" />
  },
  // {
  //   title: "Новости",
  //   path: "adminnews",
  //   icon:<NewspaperIcon fontSize="large" />
  // },
];