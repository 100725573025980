import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2'
import { CategoryScale } from 'chart.js';
import '../Admin/adminstats.css'
import Chart from 'chart.js/auto';
import moment from 'moment';
import 'moment/locale/ru';
import {useParams} from 'react-router-dom'
import Cookies from 'js-cookie'

Chart.register(CategoryScale);
const address = 'https://ftizisbiomed.ru/api'

const Adminstat = () => {
    const [KeyArray,setKeyArray] = useState([])
    const [downloadState,setDownloadState] = useState(true)
    const token = Cookies.get('_auth')
    const [users,setUsers] = useState([])
    const [counter,setCounter] = useState(0)
    const [mas,setMas] = useState([])
    const id = Object.values(useParams())
    const colors = ['#C71585','#00FFFF','#00FF00','#FF4500','#DC143C','#FF00FF','#4B0082','#0000CD','#2F4F4F','#708090','#7B68EE','#4169E1','#00BFFF','#5F9EA0','#40E0D0']
    const [loading,setLoading] = useState(true)
    const [graphLoading,setGraphLoading] = useState(true)
    useEffect(()=>{
        document.getElementById('end').value= moment().format('YYYY-MM-DD')
        document.getElementById('start').value= moment().date(1).format('YYYY-MM-DD')
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        let date = new Date().toISOString()
        fetch(address+'/get/all/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer ' + token,
        },
          body:'{"date":"'+date+'"}'
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
            if(result!==false){
            setUsers(result[0])
          }
            else alert("Неверно введённые данные")
        })
        id!=null&&id!=''&&
        fetch('https://ftizisbiomed.ru/get/users/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
            },
          body:'{"id_mas":{"mas":['+id+']},"mode":"comparative","date_start":"'+start+' 00:00:00","date_end":"'+end+' 23:59:59"}'
        }).then(res=>{if(res.ok) return res.json()}).then(resp=>{
            let massive=[]
            setKeyArray(Object.keys(Object.values(resp[0])[0]))
            let obj={label: {display: false},data: [],borderColor: ['blue',],borderWidth: 3,tension:0,hoverRadius:5,hoverBackgroundColor:'blue',}
            let login = Object.keys(resp[0])
            let values = Object.values(resp[0]).map((item=>Object.values(item)))
            login.map((item,index)=>{
                obj.hoverBackgroundColor=colors[index]
                obj.label=item
                obj.data=values[index]
                obj.borderColor=[colors[index]]
                massive.push(obj)
                obj={label: '',data: [],borderColor: ['blue',],borderWidth: 3,tension:0,hoverRadius:5,hoverBackgroundColor:'blue',}
            })
            setMas(massive)
            setCounter(resp[1])
        })
            setLoading(false)
            setGraphLoading(false)
            setTimeout(() => {
                if(id!=null&&id!='')document.getElementById(id).checked=true
            }, 1500); 
    },[])
    const handleChange = () => { // Обработчик события изменения селектора выбора периода времени
        switch(document.getElementById('ad-select').value){
            case 'default':
                break;
            case 'current_week':
                document.getElementById('start').value=moment().clone().startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().clone().endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'current_month':
                document.getElementById('start').value=moment().startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('month').format('YYYY-MM-DD');
                break;
            case 'previous_month':
                document.getElementById('start').value=moment().add(-1,'month').startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'month').endOf('month').format('YYYY-MM-DD');
                break;
            case 'current_quarter':
                document.getElementById('start').value=moment().startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'previous_quarter':
                document.getElementById('start').value=moment().add(-1,'quarter').startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'quarter').endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'current_year':
                document.getElementById('start').value=moment().startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('year').format('YYYY-MM-DD');
                break;
            case 'previous_year':
                document.getElementById('start').value=moment().add(-1,'year').startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'year').endOf('year').format('YYYY-MM-DD');
                break;
            default:break
        }
        setGraphLoading(true)
        sendData()
    }
    const handleSelect = () => {
        if (moment(document.getElementById('start').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek')))document.getElementById('ad-select').value='current_week'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('month').add(-1,'day'),moment().endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('month'),moment().endOf('month')))document.getElementById('ad-select').value='current_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'month').startOf('month').add(-1,'day'),moment().add(-1,'month').endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')))document.getElementById('ad-select').value='previous_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter')))document.getElementById('ad-select').value='current_quarter'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter')))document.getElementById('ad-select').value='previous_quarter'
        else document.getElementById('ad-select').value='random'
    }
    const click = (id) => {
        if (id===0)document.getElementById(id).checked=!document.getElementById(id).checked
        document.getElementById(id).checked=!document.getElementById(id).checked
    }
    const sel = () =>{
        var mas = document.getElementsByName('input')
        var val = document.getElementById('selectall').checked
        for (let index = 0; index < mas.length; index++) {
            mas[index].checked=val
        }
    }
    const sendData = () => {
        var id_mas = Object.values(document.getElementsByName('input')).filter((item)=>item.checked).map(item=>parseInt(item.id))
        if(id_mas.length==0){alert('Выберите пользователей'); setGraphLoading(false);return 0}
        setGraphLoading(true)
        var modif = document.getElementById('check').checked?"comparative":"general"
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value

        fetch('https://ftizisbiomed.ru/get/users/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:'{"id_mas":{"mas":['+id_mas+']},"mode":"'+modif+'","date_start":"'+start+' 00:00:00","date_end":"'+end+' 23:59:00"}'
        }).then(res=>{if(res.ok) return res.json()}).then(resp=>{
            let massive=[]
            setKeyArray(Object.keys(Object.values(resp[0])[0]))
            let obj={data: [],borderColor: ['blue',],borderWidth: 3,tension:0,hoverRadius:5,hoverBackgroundColor:'blue'}
            let login = Object.keys(resp[0])
            let values = Object.values(resp[0]).map((item=>Object.values(item)))
            if(!document.getElementById('check').checked)
            {
                obj.hoverBackgroundColor=colors[1]
                obj.label='Исследований'
                obj.data=values[0]
                obj.borderColor=[colors[1]]
                massive.push(obj)
            }
            else login.map((item,index)=>{
                obj.hoverBackgroundColor=colors[index]
                obj.label=item
                obj.data=values[index]
                obj.borderColor=[colors[index]]
                massive.push(obj)
                obj={label: '',data: [],borderColor: ['blue',],borderWidth: 3,tension:0,hoverRadius:5,hoverBackgroundColor:'blue',}
            })
            setMas(massive)
            setCounter(resp[1])
        })
            setLoading(false)
            setGraphLoading(false)
    }
    const getStatistic = () =>{
        setDownloadState(false)
        var id_mas = Object.values(document.getElementsByName('input')).filter((item)=>item.checked).map(item=>parseInt(item.id))
        if(id_mas.length==0){alert('Выберите пользователей');setDownloadState(true); return 0 }
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        fetch(address+'/download/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:'{"id_mas":{"mas":['+id_mas+']},"date_start":"'+start+' 00:00:00","date_end":"'+end+' 23:59:00"}'
        }).then((response) => response.blob()).then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Статистика (${moment(start).format('DD.MM.YYYY')}-${moment(end).format('DD.MM.YYYY')}).csv`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setDownloadState(true)
            })
    }
    return (
        <div className='admin-stat'>
            <div className='admin-stat-table'>
                <div className='admin-stat-table-title'>
                    <div className='admin-stat-table-title-text'>Пользователи</div>
                </div>
                <div className='admin-stat-table-data'>
                    {loading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <div className='stat-table-data'>
                            <table className='ad-stat-table'>
                                <thead className='ad-stat-thead'>
                                    <tr className='ad-stat-tr'>
                                        <th className='stat-tr-th'><input type='checkbox'  onChange={()=>sel()} id='selectall'/></th>
                                        <th className='stat-tr-th'>Организация</th>
                                        <th className='stat-tr-th'>Логин</th>
                                        
                                    </tr>
                                </thead>
                                <tbody className='ad-stat-tbody'>
                                    {/* {users.length===0&&(
                                        <tr className='ad-stat-tr'>
                                            <td className='stat-tr-td' colSpan='3'>Нет пользователей</td>
                                        </tr>
                                    )} */}
                                    {users.map((item)=>
                                        <tr key={item.id} className='ad-stat-tr' onClick={()=>click(item.id)}>
                                            <td className='stat-tr-td'><input id = {item.id} onChange={()=>click(item.id)} type='checkbox' name='input'/></td>
                                            <td className='stat-tr-td'>{item.organization}</td>
                                            <td className='stat-tr-td'>{item.login}</td> 
                                        </tr>
                                    )}
                                </tbody>
                            </table>  
                        </div>
                    )}
                </div>
                <div className='ad-stat-select'>
                    <select id='ad-select' onChange={()=>handleChange()}>
                        <option value='default'> </option>
                        <option value='current_week'>Текущая неделя </option>
                        <option value='current_month'>Текущий месяц </option>
                        <option value='previous_month'>Прошлый месяц </option>
                        <option value='current_quarter'>Текущий квартал </option>
                        <option value='previous_quarter'>Предыдущий квартал</option>
                        <option value='current_year'>Текущий год </option>
                        <option value='previous_year'>Прошлый год </option>
                        <option value='random'>Произвольный период </option>
                    </select>
                </div>
                <div className='admin-stat-table-date'>
                    <input className="admin-stat-table-date-input" id = 'start' type="date" onChange={()=>handleSelect()}/>
                    <input className="admin-stat-table-date-input" id = 'end' type="date" onChange={()=>handleSelect()}/>
                </div>
                <div className='admin-stat-table-date-check'>
                    Общая
                    <label className="switch">
                        <input type="checkbox" id="check" onChange={()=>sendData()}/>
                        <span className="slider round"></span>
                    </label>
                    Сравнительная
                </div>
                <div className='admin-stat-table-date-send'>
                    <button onClick={()=>sendData()}>Отобразить данные</button>
                </div>
                <div className='admin-stat-table-date-send'>
                    {/* <button>Детализация за период</button> */}
                </div>
            </div>
            <div className='admin-stat-diagram'>
                <div className='admin-stat-diagram-title'>
                    <div className='admin-stat-diagram-title-container-1'>
                        <div className='admin-stat-diagram-title-text'>
                            Статистика
                        </div>
                    </div>
                    <div className='admin-stat-diagram-title-container-2'>
                        {graphLoading?(
                            <div className='div-loading'>
                            </div>
                        ):(
                            <div className='admin-stat-diagram-title-info'>
                                Всего исследований: {counter}
                            </div>
                        )}
                    </div>
                </div>
                <div className='admin-stat-diagram-data'>
                    {graphLoading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):
                    (<Line style={{background:'white',borderRadius:10,padding:10}}
                        data={{
                        labels:moment(KeyArray[0]).year()===moment(KeyArray[KeyArray.length]).year()? KeyArray.map((val)=>{return moment(val).format('DD.MM')}):KeyArray.map((val)=>{return moment(val).format('DD.MM.YY')}),
                        datasets:mas
                        }}
                        options= {{
                            pointRadius: 1,
                            pointHoverRadius: 7,
                            responsive: true,
                            interaction: {
                              intersect: false,
                            },
                            scales: {
                              y: {
                                gridLines: {
                                    display: !(document.documentElement.scrollWidth<500)
                                },
                                title:{
                                    display:!(document.documentElement.scrollWidth<500),
                                    text:'Исследования'
                                },
                                display: true,
                                beginAtZero: true,
                                precision: 0,
                              },
                              x:{
                                gridLines: {
                                    display: !(document.documentElement.scrollWidth<500)
                                    },
                                title:{
                                display:!(document.documentElement.scrollWidth<500),
                                text:'Дата'},
                            },
                            },
                          }}/>)}
                </div>
                <div className='admin-stat-diagram-btn'>
                    {downloadState?(<button onClick={()=>getStatistic()}>Выгрузить статистику</button>):(<CircularProgress sx={{color:'white'}}/>)}
                </div>
            </div>
        </div>
    );
};

export default Adminstat;