import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { publicRoutes } from '../Routes';
import Main from '../pages/Main';
import './AppRouter.css'
import { RequireAuth } from 'react-auth-kit'
import Admin from "../pages/Admin"
import User from "../pages/User"
import {useAuthUser} from 'react-auth-kit';


const AppRouter = () => {
    const role = useAuthUser()
    return (
        <div className='container'>
            <Routes>
                {publicRoutes.map(({path,component},key)=><Route exact path={path} element={component()} key={key}/>)}
                <Route path='admin/*'element={
                    <RequireAuth loginPath={'/login'}>
                        <Admin/>
                    </RequireAuth>} />
                <Route path='user/*'element={
                    <RequireAuth loginPath={'/login'}>
                        <User/>
                    </RequireAuth>} />
                {/* {role().role&&role().role=='user'&&(<Route path='admin/*' element={<User/>}/>)} */}
                {/* {user.isAuth ? (authRoutes.map(({path,component},key)=><Route path={path} element={component()} key={key}/>)):
                (publicRoutes.map(({path,component},key)=><Route exact path={path} element={component()} key={key}/>))}
                <Route path="/*"element={<Main/>}/>
                {!user.isAuth && (<Route path='/user/*' element={<Auth/>}/>)}
                {!user.isAuth && (<Route path='/admin/*' element={<Auth/>}/>)} */}
            </Routes>
        </div>
    );
};

export default AppRouter;